import { createGraphicsDevice } from "playcanvas";
import { Scene } from "./scene";
import { getSceneConfig } from "./scene-config";
import { initMaterials } from "./material";
import { EditHistory } from "./edit-history";
import { EditorUI } from "./ui/editor";
import { registerEditorEvents } from "./editor";
import { initFileHandler, fetchPlyFromRemoteUrl } from "./file-handler";
import { initSelection } from "./selection";
import { ToolManager } from "./tools/tool-manager";
import { RectSelection } from "./tools/rect-selection";
import { BrushSelection } from "./tools/brush-selection";
import { SphereSelection } from "./tools/sphere-selection";
import { MoveTool } from "./tools/move-tool";
import { RotateTool } from "./tools/rotate-tool";
import { ScaleTool } from "./tools/scale-tool";
import { Shortcuts } from "./shortcuts";
import { Events } from "./events";

declare global {
  interface LaunchParams {
    readonly files: FileSystemFileHandle[];
  }

  interface Window {
    launchQueue: {
      setConsumer: (callback: (launchParams: LaunchParams) => void) => void;
    };
    scene: Scene;
  }
}

const getURLArgs = () => {
  // extract settings from command line in non-prod builds only
  const config = {};

  const apply = (key: string, value: string) => {
    let obj: any = config;
    key.split(".").forEach((k, i, a) => {
      if (i === a.length - 1) {
        obj[k] = value;
      } else {
        if (!obj.hasOwnProperty(k)) {
          obj[k] = {};
        }
        obj = obj[k];
      }
    });
  };

  const params = new URLSearchParams(window.location.search.slice(1));
  params.forEach((value: string, key: string) => {
    apply(key, value);
  });

  return config;
};

const initShortcuts = (events: Events) => {
  const shortcuts = new Shortcuts(events);

  shortcuts.register(["Delete", "Backspace"], { event: "select.delete" });
  shortcuts.register(["Escape"], { event: "tool.deactivate" });
  shortcuts.register(["Tab"], { event: "selection.next" });
  shortcuts.register(["1"], { event: "tool.move", sticky: true });
  shortcuts.register(["2"], { event: "tool.rotate", sticky: true });
  shortcuts.register(["3"], { event: "tool.scale", sticky: true });
  shortcuts.register(["G", "g"], { event: "grid.toggleVisible" });
  shortcuts.register(["C", "c"], { event: "tool.toggleCoordSpace" });
  shortcuts.register(["F", "f"], { event: "camera.focus" });
  shortcuts.register(["B", "b"], {
    event: "tool.brushSelection",
    sticky: true,
  });
  shortcuts.register(["R", "r"], { event: "tool.rectSelection", sticky: true });
  shortcuts.register(["P", "p"], { event: "tool.rectSelection", sticky: true });
  shortcuts.register(["A", "a"], { event: "select.all" });
  shortcuts.register(["A", "a"], { event: "select.none", shift: true });
  shortcuts.register(["I", "i"], { event: "select.invert" });
  shortcuts.register(["H", "h"], { event: "select.hide" });
  shortcuts.register(["U", "u"], { event: "select.unhide" });
  shortcuts.register(["["], { event: "tool.brushSelection.smaller" });
  shortcuts.register(["]"], { event: "tool.brushSelection.bigger" });
  shortcuts.register(["Z", "z"], { event: "edit.undo", ctrl: true });
  shortcuts.register(["Z", "z"], {
    event: "edit.redo",
    ctrl: true,
    shift: true,
  });
  shortcuts.register(["M", "m"], { event: "camera.toggleMode" });
  shortcuts.register(["D", "d"], { event: "dataPanel.toggle" });
  shortcuts.register([" "], { event: "camera.toggleDebug" });

  return shortcuts;
};

const main = async () => {
  const url = new URL(window.location.href);

  // decode remote storage details
  let remoteStorageDetails;
  let plyUrl;
  try {
    remoteStorageDetails = JSON.parse(
      decodeURIComponent(url.searchParams.get("remoteStorage"))
    );
  } catch (e) {}

  // root events object
  const events = new Events();

  // edit history
  const editHistory = new EditHistory(events);

  // editor ui
  const editorUI = new EditorUI(events, !!remoteStorageDetails);

  // create the graphics device
  const graphicsDevice = await createGraphicsDevice(editorUI.canvas, {
    deviceTypes: ["webgl2"],
    antialias: false,
    depth: false,
    stencil: false,
    xrCompatible: false,
    powerPreference: "high-performance",
  });

  // monkey-patch materials for premul alpha rendering
  initMaterials();

  const overrides = [getURLArgs()];

  // resolve scene config
  const sceneConfig = getSceneConfig(overrides);

  // construct the manager
  const scene = new Scene(events, sceneConfig, editorUI.canvas, graphicsDevice);

  // tool manager
  const toolManager = new ToolManager(events);
  toolManager.register(
    "rectSelection",
    new RectSelection(events, editorUI.toolsContainer.dom)
  );
  toolManager.register(
    "brushSelection",
    new BrushSelection(events, editorUI.toolsContainer.dom)
  );
  toolManager.register(
    "sphereSelection",
    new SphereSelection(events, scene, editorUI.canvasContainer)
  );
  toolManager.register("move", new MoveTool(events, editHistory, scene));
  toolManager.register("rotate", new RotateTool(events, editHistory, scene));
  toolManager.register("scale", new ScaleTool(events, editHistory, scene));

  window.scene = scene;

  registerEditorEvents(events, editHistory, scene, editorUI);
  initSelection(events, scene);
  initShortcuts(events);
  try {
    plyUrl = decodeURIComponent(url.searchParams.get("plyUrl"));
    console.log("got ply url v3");
    await fetchPlyFromRemoteUrl(
      "https://tb-dd.s3.ap-south-1.amazonaws.com/ml-test-data/3d_files/test.ply?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRzBFAiEAyAmAOH32OfXuatM7Jy6uF9t6fjDNQGstPwSWzirsOgsCIBVGOUogQByGt6hJpXJ%2BTuEaQcSlj4CwXTBetgOdHRI0KoYDCI%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQAhoMNzI2MjExMDMwMTc1IgymVWC6AyW%2FTnaIIJoq2gIDyCRiwybD%2FoSAHpaM%2FVCDnBTf6s44WbeqeIwy%2F6P6Bdp8ZeJJDBJdzF1p2hRV83XUwF2BQL37g9wAZ4cxYo%2BJCWFQM0r8sIOKlwV%2Bq%2FiSmO7x8vn%2Fh1N0GHjSd1QlnAHvDdfftOFclmi5wRngLHKdo2sERaXD32wJVe2SFbbLY1ZgZjJxtcj6hnCJS%2BP3Ge%2FXQK44GgofFlnchS%2BCP3P8nnP6CyOFM2S1PyrRBe%2B6461ehW%2FGC4aKpIjAhfMpw8WTvz8N4WeE8vZMvehf5Awpk7g09kzXrMpN6Wfi9pr7bbK7BhVTp1zjmLUbPyGuCeXeCD2d3ojn0WvQirVfRcMPyCFcfh4e9j65WsuQsKWFx%2F2IVaKOEW7K%2BpBwnreuiikcvpolfYN2AHKW9KZIzieu26ePdZua7NQ5ZzPuOwZqj8Kaj2CYQdI2qeniW5Of7ZpGd5PiiFm%2BmAXOMOOw6LUGOrMCeSMBs6XuPpEkhd1m0RJRy0FxNI7N3hMqlLVYPEL%2FORbVhpgxRrXVU2W0hJYggewaEhikro1sG%2Bw7xYWu3zdpDKJKe2BNgEf%2FEL3VqAtI8Vc7tOelTJmoZeCxmmPwLHkfxJJYj%2BPSvsJt95pKX70q0gUfrg36J6l2gRRsfg426j0KRj2dIlx1SSDoTTl0nHFqMRW78tWSb4%2F6WVnGzju1AaZ9%2FT6%2Fn6a92jTX6vzu9HyGUhFDg%2B5k9OI6P735n4rXSI1AbKuiV1hnOtBeE%2Fp7OgQxh8PPBlSdVXvrUXBnFJtCnM3SJJyv531TAW2EGNHyIE2hJA9raGls7qg6eqigwoByttfVMpZg1tJe03qzyXs%2FfPPt9JacIx79BqkLFOJlPKOypolkDC86q%2FrhUkAF8HDyDw%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240812T141625Z&X-Amz-SignedHeaders=host&X-Amz-Expires=14399&X-Amz-Credential=ASIA2SFMM5SPVGR6BAWN%2F20240812%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=25c9126d12097cc49394b641446225f3bbc416ee78a1152cbd19ec94ee7f0d34",
      scene
    );
    // if (plyUrl) {
    //   await fetchPlyFromRemoteUrl(plyUrl, scene);
    // }
  } catch (err) {
    console.error("fetch ply error", err);
  }
  await initFileHandler(
    scene,
    events,
    editorUI.appContainer.dom,
    remoteStorageDetails
  );

  // load async models
  await scene.load();

  // handle load param
  const loadParam = url.searchParams.get("load");
  const loadUrl = loadParam && decodeURIComponent(loadParam);
  if (loadUrl) {
    await scene.loadModel(loadUrl, loadUrl);
  }

  // handle OS-based file association in PWA mode
  if ("launchQueue" in window) {
    window.launchQueue.setConsumer(async (launchParams: LaunchParams) => {
      for (const file of launchParams.files) {
        const blob = await file.getFile();
        scene.loadModel(URL.createObjectURL(blob), file.name);
      }
    });
  }
};

export { main };
